var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("main", { staticClass: "error error--404" }, [
      _c("h1", { staticClass: "heading" }, [_vm._v("Error 404")]),
      _c("p", { staticClass: "paragraph" }, [_vm._v("Page not found")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }